// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./generated/Virgil.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/Janna LT Regular.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Virgil;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@font-face{font-family:Janna LT Regular;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}canvasd{border:1px solid #fff;box-sizing:border-box}body,html{margin:0;min-height:100vh;padding:0;width:100%}html{overflow-x:hidden;overflow-y:auto}body{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;-webkit-text-size-adjust:100%;-moz-font-feature-settings:\"liga\" on;background-color:#020307;color-scheme:dark;text-rendering:optimizeLegibility}code{font-family:Inconsolata,Monaco,Consolas,Courier New,Courier,monospace}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
