import Giscus from '@giscus/react'
import { useState } from 'react'
import { EmotionCache } from '@emotion/react'
import { matchPath, useLocation } from 'react-router'
import { Box, NoSsr, Typography, useTheme } from '@mui/material'

import { posts } from '../generated/posts'
import { useTitle } from '../hooks/use-title'
import { ThemeProvider } from '../providers/theme-provider'
import { useGlobalTransition } from '../providers/router-provider'

import { Link } from './link'
import { Sidebar } from './sidebar'
import { MDXWrapper } from './mdx-wrapper'
import { Footer } from './footer'

export function App({ cache }: { cache: EmotionCache }) {
    return (
        <ThemeProvider cache={cache}>
            <Route />
        </ThemeProvider>
    )
}

function Route() {
    const theme = useTheme()
    const { pathname } = useLocation()
    const [pending] = useGlobalTransition()
    const [pendingPost, setPendingPost] = useState('')
    const post = posts.find((p) => matchPath(p.path, pathname))

    useTitle(post?.title ?? null)

    if (post) {
        return (
            <MDXWrapper main>
                <post.post />
                <NoSsr>
                    <section>
                        <Giscus
                            term={post.title}
                            repo="fathyb/fathy.fr"
                            theme="transparent_dark"
                            repoId="R_kgDOIaiJcg"
                            loading="lazy"
                            mapping="specific"
                            reactionsEnabled="0"
                        />
                    </section>
                    <Footer
                        githubEditPath={`src/posts/${post.path}/index.mdx`}
                    />
                </NoSsr>
            </MDXWrapper>
        )
    }

    if (!matchPath('/', pathname)) {
        return <h2>not found</h2>
    }

    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    padding: theme.spacing(2),
                    zIndex: 500,

                    [`@media (min-width: ${theme.spacing(125)})`]: {
                        padding: 0,
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: theme.spacing(33),
                    },
                }}
            >
                <Sidebar />
            </Box>
            {posts
                .filter((post) => !post.hidden)
                .map((post) => (
                    <Box key={post.path} position="relative" zIndex={100}>
                        <MDXWrapper
                            sx={{
                                overflow: 'hidden',
                                position: 'relative',
                                maxHeight: theme.spacing(60),
                                maskImage:
                                    'linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 90%)',
                            }}
                        >
                            <post.preview />
                        </MDXWrapper>

                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: theme.spacing(1),
                                right: 0,
                                left: 0,

                                textAlign: 'center',
                            }}
                        >
                            <Typography>
                                <Link
                                    to={post.path}
                                    onClick={() => {
                                        setPendingPost(post.path)
                                    }}
                                >
                                    {pending && pendingPost === post.path
                                        ? 'Loading..'
                                        : 'Read more..'}
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                ))}
        </>
    )
}
