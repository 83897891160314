import { lazy } from 'react'
export const posts = [

        {
            path: "/carbonyl",
            title: "Forking Chrome to render in a terminal",
            hidden: false,
            post: lazy(() =>
                import("./carbonyl.entry")
            ),
            description: lazy(() =>
                import("../posts/carbonyl/index.description.gen.mdx")
            ),
            preview: require("../posts/carbonyl/index.preview.gen.mdx").default,
        }
    ,

        {
            path: "/every-sin",
            title: "Every thing is a sin()",
            hidden: true,
            post: lazy(() =>
                import("./every-sin.entry")
            ),
            description: lazy(() =>
                import("../posts/every-sin/index.description.gen.mdx")
            ),
            preview: require("../posts/every-sin/index.preview.gen.mdx").default,
        }
    ,

        {
            path: "/html2svg",
            title: "Forking Chrome to turn HTML into SVG",
            hidden: false,
            post: lazy(() =>
                import("./html2svg.entry")
            ),
            description: lazy(() =>
                import("../posts/html2svg/index.description.gen.mdx")
            ),
            preview: require("../posts/html2svg/index.preview.gen.mdx").default,
        }
    
]